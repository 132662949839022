#Home {
  height: 100%;
  width: 100%;
  text-align: center;
  overflow-y: scroll;
  overflow-x: hidden;
}
 #split_port {
  height:75%;
  width: 100%;
  padding-bottom: 2.5rem;
  display: inline-block;
  margin: 10px;

}
#split_port > div {
  width: 50%;
  display: inline-block;
}
p {
  color: white;
  font-size: 24px;
}
p.home_text {
  margin: 10px;
}
h2 {
  color: white;
  text-align: center;
  font-size: 48px;
}

#Profile-Pic {
  height: 400px;
  display: inline;
  border-radius: 25%;
  position: relative;
  margin-left: 10%;
  margin-bottom: 15%;
}

.a_links {
  color: #FF8000;
}
#Home_text_next_to_pic {
  text-align: center;
  margin-top: 30px;
  width: 55%;
  margin-left: 30px;
}

h1.title {
  font-size: 3rem/* 48px */;
  line-height: 1;
  font-weight: 700;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem/* 16px */;
}