#Work {
  text-align: center;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0;
  width: 100%;
  height: 100%;
}
#Project{
    width: 100%;
    display: inline-block;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-bottom: 2.5rem;
}
