/* Popup.css */
.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(8px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; 
  }
  
  .popup-content {
    background-color: #585858; 
    color: #fff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    position: relative;
    text-align: center;
    width: 70%;
    height: 70%;
    flex-direction: column;
    overflow: scroll;
  }
  
  .popup-image {
    width: 50%;
    height: 50%;
    overflow:hidden;
  }
  
  .popup-image-full {
    width: 100%;
    max-height: 50%;
  }

  .popup-content-container {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  
  .popup-text {
    width: 50%;
    padding-left: 20px;
    box-sizing: border-box;
  }

  .popup-tex-bottom {
    width: 100%;
    margin-top: 20px;
  }
  
  .close {
    position: absolute;
    top: 0px;
    right: 15px;
    font-size: 40px;
    font-weight: bold;
    cursor: pointer;
  }
  
  /* Additional styles for the input title */
  .input-title {
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    font-size:3.2rem;
    width: 100%;
    text-overflow: ellipsis;
  }
  
  /* Additional styles for the input box */
  .input-box {
    padding: 10px;
    border-radius: 5px;
    text-align: left;
    margin-bottom: 10px;
    color: #fff;
  }
  .text-box {
    color: #fff;
    border-radius: 5px;
    padding: 10px;
    padding-right: 10px;
    text-align: left;
    margin-top : 20px; 
    margin-right: 20px;
    width: fit-content;
  }
  
  p.input-box   {
    color: #fff;
    margin-top: 10px;
    font-size: 2rem;
  }
  .header_text{
    font-size: 2rem/* 48px */;
    line-height: 1;
    text-align: left;
    margin-top: 0.5rem/* 8px */;
  }
  button.github{
    display: block;
    margin-top: 15px;
    width: fit-content;
    padding: 12px;
    text-align: left;
    font-size: 24px;
    color: white;
    border-radius: 6px;
    background-origin: border-box;
  }
  button.github#github_available:active {
    background: orange;
  }
  button.github#github_NA:active {
    background: orange;
  }
  button.github#github_available {
    background: linear-gradient(180deg, #40e2f4 0%, #2aadb6 100%);

}

button.github#github_NA {
  background: linear-gradient(180deg, #f44040 0%, #b62a2a 100%);
}