img.project-image{
    object-fit: contain;
    height: 24rem;
    width: 24rem;
}
button#myButton:hover  img.project-image{
    filter: brightness(25%);
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 1s ease;
}
span.project_details {
    display: none;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    padding: 12px;
    font-size: 24px;
    color: white;
    background: linear-gradient(180deg, #4B91F7 0%, #367AF6 100%);
    border-radius: 6px;
    text-align: center;
    background-origin: border-box;
}
button:hover span {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index:1;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 5px 5px white;
}

button:hover span:active {
    background: orange;
}

h3.project_title {
    font-size: 36px;
    color:white;
}

div.project_Tabs{ 
    width: 33.3333%;
    display: inline-block;
    margin-top: 20px;
    margin-bottom: 20px;
    position: relative;
    
}