#footer {
    text-align: center;
    justify-content: center;
    position: relative;
    bottom: 0;
    font-size:  20px;
    padding: 3px;
    width: 100%;
    height: 2.5rem;
    display: block;
    background-color: transparent;
    color: white;
  }