#header-div {
    display: inline-block;
    background-color: transparent;
    width: 100%;
    height: 12.5%;
  }
  #dashboard > ul.horizontal_list {
  list-style: none;
  height: 100%;
  width: fit-content;
}
ul.horizontal_list li {
  display: inline-block;
  margin-inline-start:  30px;
  margin-inline-end:  30px;
}

#dashboard {
    width:fit-content;
    height: 55px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    border: 2px solid #ffffff;
    border-image: linear-gradient(white,white),linear-gradient(90deg,#ffffff,#bfbfbf,#ffffff,#bfbfbf);
    background-origin: border-box;
    background-color: transparent;
    background-clip: content-box, border-box;
    border-image-slice: 1;
    border-radius: 30px;
    font-size: 30px;
    margin: 20px auto;
  }



a.App-link {
    border-width:3px;
    border-spacing:30px;
    height: 25%;
    color: white;
    text-decoration: none;
    margin: 2ch;
    border-style: none;
  }
 a.App-link:hover {
  color:#FF8000;;
 }