.Interest {
    text-align: center; 
    height: 100%;
    width: 100%;
    padding: 0;
    overflow-y: auto;
    overflow-x: hidden;
  }
h3.hobby_title {
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  font-size: 2.25rem/* 36px */;
  line-height: 2.5rem/* 40px */;
  margin-left: 0.75rem/* 12px */;
  margin-bottom: 0.75rem/* 12px */;
  width: fit-content;
}
p.hobby_Text {
  text-align: left;
  margin-bottom: 1rem/* 16px */;
  padding: 1rem/* 16px */;
  line-height: 1.6;
  font-size: 24px;
}