.Resume {
    text-align: center;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0;
    width: 100%;
    height: 100%;
}

div.top_portion {
    text-align: center;
    width: 33.333%;
    height: 20%;
    display: inline-block;
    margin-top: 2.5rem/* 40px */;

}

div.middle_portion {
    text-align: center;
    width: 10%;
    height: 80%;
    margin: 20px auto;

}

div.bottom_portion {
    height: fit-content;
    width: 80%;
    margin: 2rem auto;
    text-align: center;
}

button.class {
    border-radius: 6px;
    font-size: 24px;
    background-origin: border-box;
    color: white;
    margin: 20px;
    padding: 12px;
}
button.class#word {
    background: linear-gradient(180deg, #4085f4 0%, #2a52b6 100%);
}
button.class#pdf {
    background: linear-gradient(180deg, #f44040 0%, #b62a2a 100%);
}

button.class#view {
    background: green;
    padding: 10px;
    margin: 10px;
}