.About {
  text-align: center;
  height: 100vh;
  width: 100vw;
  padding: 0;
  overflow-y: scroll;
  overflow-x: auto;
}
h1 {
  color:white;
}

.pictures {
  height: 33%;
  width: 33%;
  display: inline;
}

p.about-text {
  text-align: left;
  margin-bottom: 1rem/* 16px */;
  padding: 1rem/* 16px */;
  line-height: 1.5rem/* 24px */;
  font-size: 24px;
}

img.waterfall {
  object-fit: fill;
  height: 600px; 
}